import React,{useContext} from 'react'
import { NavLink } from "react-router-dom";
import { fetchMenuItems } from '../Api/Queries'
import { useQuery } from 'react-query'
import {AppContext} from '../context/AppContext'

import loadable from '@loadable/component'

const MenuSkeleton = loadable(() => import('../skeletons/MenuSkeleton'));
export default function Menu() {

    const {language,lang_id,switchLanguage } = useContext(AppContext);
    const { 
        data:menuItems,
        status,
        error 
    } = useQuery(['menu-items',lang_id],fetchMenuItems,{
        refetchOnMount:false,
        refetchInterval:false,
        refetchOnWindowFocus:false,
    });

  

    return (
        <section className="main-menu">
       
       {
        status!="loading" ? (
           <>
           <ul>
            {
                menuItems.map((item,i)=>{
                    return (
                        <li key={i}>
                            <NavLink className="btn" to={ item.link }>{item.name}</NavLink>
                        </li>
                    )
                })
            }
               
            </ul>
            {
                language ? (
                        <a className="btn theme-container lang-flate" onClick={()=>{ switchLanguage() }} href="#">{language.key}</a>
                ): undefined
            } 
           </>
        ) :(
            <MenuSkeleton/>
        )
       }
            
        </section>
    )
}
