import React from 'react'
import Front from "./pages/Front"
import Contact from "./pages/Contact"
import Resume from "./pages/Resume"
import Portfolio from "./pages/Portfolio"
import Lost from "./pages/Lost"
import Menu from './layout/Menu'
import ScrollToMain from './hooks/ScrollToMain'
import AppContextProvider from './context/AppContext'
import { AnimatePresence } from "framer-motion";
import MenuMobile from './layout/MenuMobile'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation 
  } from "react-router-dom";

export default function Routes() {
    // const location = useLocation();
    const pageVariants = {
        initial: {
          opacity: 0,
          scale: 0.8
        },
        in: {
          opacity: 1,
          scale: 1
        },
        out: {
          opacity: 0,
          scale: 1.2
        }
      };
      
      const pageTransition = {
        type: "tween",
        ease: "anticipate",
        duration: 0.2
      };
      
     
    return (
        <Router>
            <Menu/>
            <MenuMobile/>
             <ScrollToMain />

          <AnimatePresence>
            <Switch  >
                <Route exact={true} path="/contact">
                    <Contact  pageVariants={pageVariants}  pageTransition={pageTransition}/>
                </Route>
                <Route exact={true} path="/portfolio">
                    <Portfolio  pageVariants={pageVariants} pageTransition={pageTransition}/>
                </Route>
                <Route exact={true} path="/resume">
                    <Resume  pageVariants={pageVariants} pageTransition={pageTransition}/>
                </Route>
                <Route exact={true} path="/">
                    <Front  pageVariants={pageVariants}  pageTransition={pageTransition}/>
                </Route> 
                <Route exact={true} >
                    <Lost/>
                </Route> 
            </Switch>
            </AnimatePresence>
        </Router>
    )
}
