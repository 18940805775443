import React from 'react'
import parse from 'html-react-parser';
import SkillsSkeletonComponent from '../skeletons/SkillsSkeletonComponent'

export default function SkillsComponent({title,items,status}) {
    return (
        status != "loading" ? ( 
        <React.Fragment>
             <h2 className="block-title">{parse(title)}</h2>
                <div className="design-skills">
                    {
                        items?.map((item,i)=>{
                            return (
                                <div className="ui-progress-wapper" key={i}>
                                    <div className="ui-progress" style={{ width: item.level+'%' }} >
                                        <p>{parse(item.title)}</p>
                                        <p>{parse(item.level)} %</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                  
                </div>
        </React.Fragment>
         ):(
            <SkillsSkeletonComponent/>
         )
    )
}
