export const enPlaceHolders = {
    full_name: 'Full name',
    subject: 'Subject',
    email: 'Email',
    phone: 'Phone',
    message: 'Message',
}

export const frPlaceHolders = {
    full_name: 'Nom et prénom',
    subject: 'Sujet',
    email: 'Adresse e-mail',
    phone: 'Téléphone',
    message: 'Message',
}

export const frLocos = {
    loading: 'Chargement ...',
    success_alert: 'Message envoyé',
    form_contact_btn_text: 'Envoyer',
    dialog_title: 'Parfaits',
    dialog_sub_title: 'Votre message a <br /> été envoyé ',
    dialog_desc: 'Je vous contacterai dans les plus brefs délais.',
}

export const enLocos = {
    loading: 'Loading ...',
    success_alert: 'Message sent',
    form_contact_btn_text: 'Send',
    dialog_title: 'Perfect',
    dialog_sub_title: 'Your message has <br /> been sent ',
    dialog_desc: 'I will contact you as soon as possible .',
}