import React from 'react'
import {  motion } from "framer-motion";
import loadable from '@loadable/component'

import ContactForm from '../components/ContactForm';
import MetaTagsComponent from '../layout/MetaTags';

export default function Contact({pageVariants,pageTransition,pageStyle}) {
    const extras = {
        meta_title:"Contact Boutamente Abdessamad | Full Stack Web Developer",
        meta_description:"full-stack web Developer and Project Manager with Leadership Experience , with more than 4 years of experience in working on high-level projects with small-sized teams.",
        meta_keywords:"web ,Developer,development,Boutamente ,Abdessamad,design,integration,web design, php,laravel,node js ,js ,reactjs,expressjs,"
    }
    return (
        <React.Fragment>
            <MetaTagsComponent extras={extras}/>
            <motion.section
                 style={pageStyle}
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}
                    className="contact">
                
                <h2 className="main-title">Contact</h2>

                <div className="contact-zone">
                    <img src="./assets/images/contact/envelope.gif" alt=""/>
                </div>

             
                {/* ContactForm */}
                <ContactForm/>
                {/* ContactForm */}


            </motion.section>
        </React.Fragment>
    )
}
