import React,{useEffect,useContext} from 'react'
import { NavLink } from "react-router-dom";
import {AppContext} from '../context/AppContext'
import { useQuery } from 'react-query'
import { fetchMenuItems } from '../Api/Queries'
import loadable from '@loadable/component'

const MenuMobileSkeleton = loadable(() => import('../skeletons/MenuMobileSkeleton'));
export default function MenuMobile() {

  const {language,switchLanguage,lang_id } = useContext(AppContext);

  const { 
      data:menuItems,
      status,
      error 
  } = useQuery(['menu-items',lang_id],fetchMenuItems,{
      refetchOnMount:false,
      refetchInterval:false,
      refetchOnWindowFocus:false,
  });

    const  addClass = (elem) =>{
        for (let i = 0; i < elem.length; i++) {
          elem[i].addEventListener("click", function(e) {
            const current = this;
            for (let i = 0; i < elem.length; i++) {
              if (current !== elem[i]) {
                elem[i].classList.remove("isActive");
                elem[i].classList.add("notActive");
              } else {
                current.classList.add("isActive");
                current.classList.remove("notActive");
              }
            }
            e.preventDefault();
          });
        }
      }
     
      useEffect(() => {
        addClass(document.querySelectorAll(".link"));
      }, [])

    return (
    <nav className="menu-mobile">
     {
        status!="loading" ? (
        <ul>
          {
            menuItems.map((item,i)=>{
                return (
                  <li key={i}>
                    <NavLink  exact={true} onClick={addClass(i)} to={ item.link } className="link">
                      <i className={"fa " + item.icon}></i>
                        <span>{item.name}</span>
                    </NavLink>
                  </li>
                )
            })
          }
         
        </ul>
        ) :(
            <MenuMobileSkeleton/>
        )
     }
      {
          language ? (
              <div className="theme-container shadow-light lang-flate"  onClick={()=>{ switchLanguage() }}>
                      {language.key}
              </div>
          ): undefined
      } 
    </nav>
    )
}
