import React from 'react'
import {  motion } from "framer-motion";
export default function Portfolio({pageVariants,pageTransition,pageStyle}) {
    return (
        <React.Fragment>
          <motion.div
                 style={pageStyle}
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
        <section className="my-portfolio">
                            
        <h2 className="main-title mb-5">Portfolio</h2>

        <ul className="portfolio-menu d-none">
            <li><a href="" className="active">All</a></li>
            <li><a href="">React Js</a></li>
            <li><a href="">WordPress</a></li>
            <li><a href="">Laravel</a></li>
            <li><a href="">Node js</a></li>
        </ul>

        <div className="portfolio-projects">
            <div className="row gx-5">
                <div className="col-12 col-md-4">
                    <div className="project-item">
                        <img src="./assets/images/project/1.jpg" alt=""/>
                        <h2 className="project-title">Project 1</h2>
                        <p className="project-techno">Laravel</p>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="project-item">
                        <img src="./assets/images/project/2.jpg" alt=""/>
                        <h2 className="project-title">Project 1</h2>
                        <p className="project-techno">Laravel</p>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="project-item">
                        <img src="./assets/images/project/3.jpg" alt=""/>
                        <h2 className="project-title">Project 1</h2>
                        <p className="project-techno">Laravel</p>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="project-item">
                        <img src="./assets/images/project/4.jpg" alt=""/>
                        <h2 className="project-title">Project 1</h2>
                        <p className="project-techno">Laravel</p>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="project-item">
                        <img src="./assets/images/project/5.jpg" alt=""/>
                        <h2 className="project-title">Project 1</h2>
                        <p className="project-techno">Laravel</p>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="project-item">
                        <img src="./assets/images/project/6.jpg" alt=""/>
                        <h2 className="project-title">Project 1</h2>
                        <p className="project-techno">Laravel</p>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="project-item">
                        <img src="./assets/images/project/7.jpg" alt=""/>
                        <h2 className="project-title">Project 1</h2>
                        <p className="project-techno">Laravel</p>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="project-item">
                        <img src="./assets/images/project/8.jpg" alt=""/>
                        <h2 className="project-title">Project 1</h2>
                        <p className="project-techno">Laravel</p>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="project-item">
                        <img src="./assets/images/project/9.jpg" alt=""/>
                        <h2 className="project-title">Project 1</h2>
                        <p className="project-techno">Laravel</p>
                    </div>
                </div>
            </div>
        </div>

    </section>
    </motion.div>
    </React.Fragment>
    )
}
