const host = process.env.REACT_APP_HOST;
const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Headers": "X-Custom-Header",
}
// get content 
export const sendMailContact = async (args)=>{
    
    return await fetch(`${host}/api/contact`, {
        method: 'POST', // or 'PUT'
        headers: headers,
        body: JSON.stringify(args),
    })
    .then(response => response.json())
    .then(data => {
       return data;
    })
    .catch((error) => {
        return error;
    });

}