import React,{useContext} from 'react';
import {  motion } from "framer-motion";
import { useQuery } from 'react-query'
import {fetchPagesContent } from '../Api/Queries'
import {AppContext} from '../context/AppContext'
import loadable from '@loadable/component'
// components 
import MetaTagsComponent from '../layout/MetaTags';
import ResumeTimeLineComponent from '../components/ResumeTimeLineComponent';
import SkillsComponent from '../components/SkillsComponent';

export default function Resume({pageVariants,pageTransition,pageStyle}) {
    const {lang_id } = useContext(AppContext);

    const { 
        data:content,
        status,
        error 
    } = useQuery(['content',lang_id],fetchPagesContent,{
        refetchOnMount:false,
        refetchInterval:false,
        refetchOnWindowFocus:false,
    });



    const extras = status != "loading" ? content.find(item => item.slug == "resume").extras : {};
    const resumeContent = status != "loading" ? content.find(item => item.slug == "resume").content : null;
    const educations = resumeContent ? JSON.parse(resumeContent.education) : null;
    const experiences = resumeContent ? JSON.parse(resumeContent.experience) : null;
    const design_skills = resumeContent ? JSON.parse(resumeContent.design_skills) : null;
    const coding_skills = resumeContent ? JSON.parse(resumeContent.coding_skills) : null;
    return (
        <React.Fragment>
         <MetaTagsComponent extras={extras}/>
          <motion.div
                 style={pageStyle}
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}>
              <section className="my-resume">
                            
                <h2 className="main-title">Resume</h2>

                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="education">
                            <ResumeTimeLineComponent title={resumeContent?.education_title} items={educations} status={status} icon="education.svg"/>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="experience">
                            <ResumeTimeLineComponent title={resumeContent?.experience_title} items={experiences} status={status} icon="experience.svg"/>
                        </div>
                    </div>
                </div>

            </section>

            <section className="my-skills">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <SkillsComponent title={resumeContent?.design_skills_title} items={design_skills} status={status}/>
                    </div>
                    <div className="col-12 col-lg-6">
                        <SkillsComponent title={resumeContent?.coding_skills_title} items={coding_skills} status={status}/>
                    </div>
                </div>
            </section>
            </motion.div>
        </React.Fragment>
    )
}
