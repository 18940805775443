import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';

export default function TechnologiesSkeletonComponent() {
    return (
        <React.Fragment>
             <h2 className="block-title"> <Skeleton variant="text" animation="wave" width={"100%"}   height={20}  /></h2> 
                <div className="row technologies-row">

                    <div className="col-12 col-lg-6">
                        <div className="technology-box">
                            <div className="techno-icon-wapper">
                                <Skeleton variant="rect" animation="wave" width={"100%"}  height={"100%"}  />
                            </div>
                            <Skeleton variant="text" animation="wave" width={"100%"}   height={20}  />
                            <Skeleton variant="text" animation="wave" width={"60%"}   height={20}  />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="technology-box">
                        <div className="techno-icon-wapper">
                                <Skeleton variant="rect" animation="wave" width={"100%"}  height={"100%"}  />
                            </div>
                            <Skeleton variant="text" animation="wave" width={"100%"}   height={20}  />
                            <Skeleton variant="text" animation="wave" width={"60%"}   height={20}  />
                        </div>
                    </div>

                </div>
        </React.Fragment>
    )
}
