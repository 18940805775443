import React from 'react'
import { Link } from "react-router-dom";

export default function Lost() {
    return (
        <div className="lost-page" style={{ backgroundImage: `url('./assets/images/lost.jpg')` }}>
            <div className="lost-page-content">
                <h1>404</h1>
                <h3>Oops! You ran out <br /> of oxygen</h3>
                <Link to="/" className="ui-dialog-btn">Back To Home</Link>
            </div>
        </div>
    )
}
