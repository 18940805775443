import React, { createContext,useState,useEffect } from 'react'

import { fetchLanguages } from '../Api/Queries'
import { useQuery } from 'react-query'

export const AppContext = createContext();

export default function AppContextProvider({children}) {
    // state 
    const [language , setLanguage] = useState(null);
    const [lang_id , setLang_id] = useState(null);
    const [messageSent,setMessageSent] = useState(false);
    const { 
        data:languages,
        status:languagesStatus,
        error :languagesError
    } = useQuery('languages',fetchLanguages,{
        refetchOnMount:false,
        refetchInterval:false,
        refetchOnWindowFocus:false,
    });

    useEffect(() => {
        const inactiveLang = languagesStatus!="loading" && languages  ? languages.find(lang=> !lang.is_current) : null;
        setLanguage(inactiveLang);
        console.log(language);
    }, [languages])

    const switchLanguage = ()=>{
        setLang_id(language.id);
        const inactiveLang = languagesStatus!="loading" && languages  ? languages.find(lang=> lang.id != language.id) : null;
        setLanguage(inactiveLang);
    }
    return (
        <AppContext.Provider value={{
            language :language,
            lang_id:lang_id,
            messageSent:messageSent,
            switchLanguage :switchLanguage,
            setMessageSent:setMessageSent
        }}>
            {children}
        </AppContext.Provider>
    )
}
