import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToMain({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
       document.getElementById("main_app").scrollIntoView();
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

export default withRouter(ScrollToMain);