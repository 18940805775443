import Routes from './Routes'
import Layout from './layout/Layout'
import { QueryClient, QueryClientProvider } from 'react-query'
 
const queryClient = new QueryClient()

const  App = ()=> {

  return (
    <div className="App">
     <QueryClientProvider client={queryClient}>
      <Layout>
        <Routes/>
      </Layout>
     </QueryClientProvider>
     
         
    </div>
  );
}

export default App;