import React from 'react'
import MetaTags from 'react-meta-tags';

export default function MetaTagsComponent({extras}) {
    return (
            extras ?
                <MetaTags>
                    <title>{extras.meta_title}</title>
                    <meta name="description" content={extras?.meta_description} />
                    <meta property="og:title" content={extras?.meta_title} />
                    <meta name="keywords" content={extras?.meta_keywords}/>
                </MetaTags>
            : <title>Boutamente Abdessamad | Développeur Web Full Stack</title>
           
            
    )
}
