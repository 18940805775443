import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';
export default function SkillsSkeletonComponent() {
    return (
        <div>
            <Skeleton variant="text" animation="wave" width={"30%"}   height={20}  />
            <div className="coding-skills">
                <div className="ui-progress-wapper">
                    <Skeleton variant="text" animation="wave" width={"40%"}   height={20}  />
                    <Skeleton variant="text" animation="wave" width={"60%"}   height={20}  />
                </div>
                <div className="ui-progress-wapper">
                    <Skeleton variant="text" animation="wave" width={"40%"}   height={20}  />
                    <Skeleton variant="text" animation="wave" width={"60%"}   height={20}  />
                </div>
                <div className="ui-progress-wapper">
                    <Skeleton variant="text" animation="wave" width={"40%"}   height={20}  />
                    <Skeleton variant="text" animation="wave" width={"60%"}   height={20}  />
                </div>
                <div className="ui-progress-wapper">
                    <Skeleton variant="text" animation="wave" width={"40%"}   height={20}  />
                    <Skeleton variant="text" animation="wave" width={"60%"}   height={20}  />
                </div>
            </div>  
        </div>
    )
}
