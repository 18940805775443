import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton'

export default function DataBasesSkeletonComponent() {
    return (
        <React.Fragment>
        
            <h2 className="block-title">
                 <Skeleton variant="text" animation="wave" width={"30%"}   height={20}  />
            </h2>
            
            <div className="databases-bock">

                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="database-item">
                            <Skeleton variant="rect" animation="wave" width={"100%"}  height={"100%"}  />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="database-item">
                            <Skeleton variant="rect" animation="wave" width={"100%"}  height={"100%"}  />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="database-item">
                            <Skeleton variant="rect" animation="wave" width={"100%"}  height={"100%"}  />
                        </div>
                    </div>

                </div>

            </div>

        </React.Fragment>
    )
}
