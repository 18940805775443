import React from 'react'
import parse from 'html-react-parser';
import Skeleton from '@material-ui/lab/Skeleton';

export default function WorkComponent({title,domains,status}) {
    const host = process.env.REACT_APP_HOST;
    return (
        <section className="my-work">
            {
            status != "loading" ? ( 
                <div className="zone-desc">

                    <React.Fragment>
                        <h2 className="block-title">{parse(title)}</h2> 
                        <div className="row g-4 job-boxs">
                        {
                            domains.map((domain,i)=>{
                                return (
                                    <div className="col-12 col-lg-6" key={i}>
                                        <div className="job-box">
                                            <div className="job-icon">
                                                <img src={host+'/'+domain.Icon} alt=""/>
                                            </div>
                                            <div className="job-details">
                                                <h2>{parse(domain.title)}</h2>
                                                <p>{parse(domain.description)}</p>
                                            </div>
                                            
                                        </div>
                                    </div>
                                )
                            })
                        }
                        </div>
                    </React.Fragment>
                
                </div>):(

                    <React.Fragment>
                        <h2 className="block-title"><Skeleton variant="text" animation="wave"  width={"100%"}  height={20}  /></h2> 
                        <div className="row g-4 job-boxs">

                            <div className="col-12 col-lg-6">
                                <div className="job-box">
                                    <div className="job-icon">
                                        <Skeleton variant="rect" animation="wave" width={"100%"}  height={"100%"}  />
                                    </div>
                                    <div className="job-details">
                                        <Skeleton variant="text" animation="wave" width={"100%"}   height={20}  />
                                        <Skeleton variant="text" animation="wave" width={"60%"}   height={20}  />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="job-box">
                                    <div className="job-icon">
                                        <Skeleton variant="rect" animation="wave" width={"100%"}  height={"100%"}  />
                                    </div>
                                    <div className="job-details">
                                        <Skeleton variant="text" animation="wave" width={"100%"}   height={20}  />
                                        <Skeleton variant="text" animation="wave" width={"60%"}   height={20}  />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </React.Fragment>
            )}

        </section>
    )
}
