import React from 'react'
import parse from 'html-react-parser';
import Skeleton from '@material-ui/lab/Skeleton';
export default function AboutComponent({about_me,status}) {
    return (
        <section className="about-me">
                    
        <h2 className="main-title">About Me</h2>
            {
                status != "loading" ? 
                (<div className="zone-desc">
                    {parse(about_me)}
                </div>):
                (
                <div className="zone-desc">
                    <Skeleton width={"60%"} />
                    <Skeleton animation={false} width={"80%"} />
                    <Skeleton animation="wave" width={"100%"} />
                    <Skeleton animation="wave" width={"40%"} />
                    <Skeleton width={"60%"} />
                    <Skeleton animation={false} width={"80%"} />
                    <Skeleton animation="wave" width={"100%"} />
                    <Skeleton animation="wave" width={"40%"} />
                    <Skeleton width={"60%"} />
                    <Skeleton animation={false} width={"80%"} />
                    <Skeleton animation="wave" width={"100%"} />
                    <Skeleton animation="wave" width={"40%"} />
                    <Skeleton width={"60%"} />
                    <Skeleton animation={false} width={"80%"} />
                    <Skeleton animation="wave" width={"100%"} />
                    <Skeleton animation="wave" width={"40%"} />
                    <Skeleton width={"60%"} />
                    <Skeleton animation={false} width={"80%"} />
                    <Skeleton animation="wave" width={"100%"} />
                    <Skeleton animation="wave" width={"40%"} />
                </div>)}
       

    </section>
    )
}
