import React from 'react'
import parse from 'html-react-parser';
import ResumeSkeletonComponent from '../skeletons/ResumeSkeletonComponent'

export default function ResumeTimeLineComponent({title,items,status,icon}) {
    return (
        
            status != "loading" ? ( 
                <React.Fragment>
        
                <h2 className="block-title"> <img src={"./assets/icons/" +icon} alt=""/> {parse(title)}</h2>
                <ul className="timeline">
                    {
                        items?.map((item,i)=>{
                            return (
                                <li key={i}>
                                    <h2>{parse(item.title)}</h2>
                                    <span>{parse(item.date)}</span>
                                    <p>{parse(item.description)}</p>
                                </li>
                            )
                        })
                    }
                </ul>
            </React.Fragment>
             ):(
                <ResumeSkeletonComponent/>
             )
        
       
    )
}
