import * as Yup from 'yup';

const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/
export const ContactValidationEn = Yup.object().shape({
    full_name: Yup.string()
    .min(2, 'Full name is too short! min 2 letters')
    .max(60, 'Full name is too long! max 60 letter')
    .required('Full name is Required'),

    email: Yup.string().email('Invalid email').required('Email required'),

    subject: Yup.string()
        .min(2, 'Subject is too short! min 2 letters')
        .max(100, 'Subject is too long! max 60 letter')
        .required('Subject is Required'),
    
    phone: Yup.string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .min(10, 'Phone is too short! min 10 number')
        .max(15, 'Phone is too long! max 15 number')
        .required('Phone is Required'),

    message: Yup.string()
    .min(100, 'Message is too short! min 100 letter')
    .max(5000, 'Message is too long! max 15 number')
        
  });

  export const ContactValidationFr = Yup.object().shape({
    full_name: Yup.string()
    .min(2, 'Le nom complet est trop court! min 2 lettres')
    .max(60, 'Le nom complet est trop long! max 60 lettre')
    .required('Le nom complet est obligatoire'),

    email: Yup.string().email('Email invalide').required('Email est obligatoire'),

    subject: Yup.string()
        .min(2, 'Le sujet est trop court! min 2 lettres')
        .max(100, 'Le sujet est trop long! max 60 lettre')
        .required('Le sujet est obligatoire'),
    
    phone: Yup.string()
        .matches(phoneRegExp, 'Le numéro de téléphone n\'est pas valide')
        .min(10, 'Le téléphone est trop court! nombre min 10')
        .max(15, 'Le téléphone est trop long! max 15 nombre')
        .required('Le téléphone est obligatoire'),

    message: Yup.string()
    .min(100, 'Le message est trop court! min 100 lettre')
    .max(5000, 'Le message est trop long! max 5000 lettre')
        
  });