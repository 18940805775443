import React from 'react'
import parse from 'html-react-parser';
import DataBasesSkeletonComponent from '../skeletons/DataBasesSkeletonComponent'

export default function DatabasesComponent({title,databases,status}) {
    const host = process.env.REACT_APP_HOST;
    return (
        status != "loading" ? ( 
        <React.Fragment>
        
        <h2 className="block-title">{parse(title)}</h2>
        
        <div className="databases-bock">

            <div className="row">
            {
                databases.map((database,i)=>{
                    return (
                        <div className="col-12 col-md-4" key={i}>
                            <div className="database-item" key={i}>
                                <img src={host+'/'+database.Icon} alt=""/>
                            </div>
                        </div>
                    )
                })
            }
              

            </div>

        </div>

        </React.Fragment>
        ):(
            <DataBasesSkeletonComponent/>
        )
    )
}
