import React from 'react'
import parse from 'html-react-parser';
import TechnologiesSkeletonComponent from '../skeletons/TechnologiesSkeletonComponent'

export default function TechnologiesComponent({title,technologies,status}) {
    const host = process.env.REACT_APP_HOST;
    return (
        <section className="technologies">
         {
            status != "loading" ? ( 
                <React.Fragment>
                    <h2 className="block-title"> {parse(title)}</h2> 
                    <div className="row technologies-row gy-5">
                    {
                        technologies.map((backend_technology,i)=>{
                            return (
                                <div className="col-12 col-lg-6" key={i}>
                                    <div className="technology-box">
                                        <div className="techno-icon-wapper white">
                                            <img src={host+'/'+backend_technology.Icon} alt=""/>
                                        </div>
                                        <h2>{parse(backend_technology.title)}</h2>
                                        <p>{parse(backend_technology.description)}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                       
                    </div>
                </React.Fragment>
            ):(
               <TechnologiesSkeletonComponent/>
            )
        } 

    </section>
       
    )
}
