import React,{useState,useEffect} from 'react';
import AppContextProvider from '../context/AppContext';
import loadable from '@loadable/component'

const SideBar = loadable(() => import('./SideBar'));
const MainApp = loadable(() => import('./MainApp'));



const Layout =({children}) =>{

    const [dark,setDark] = useState(true);

    useEffect(() => {
        const doc = document.documentElement.style;
        if (dark) {
            document.querySelector('body').classList.add('dark-mode');
            document.querySelector('body').classList.remove('light-mode');
            doc.setProperty('--color-darken-blue',"#272333");
            doc.setProperty('--color-dark-blue',"#302C40");
            doc.setProperty('--color-superdark-blue',"#2C283A");
            doc.setProperty('--color-purple',"#3F59FD");
            doc.setProperty('--color-crem',"#F1F2F4");
            doc.setProperty('--color-white',"#FFFFFF");
            doc.setProperty('--color-crem-dark',"#DEDDE0");
            doc.setProperty('--color-dark-purple',"#304cfd");
            doc.setProperty('--activity-shadow',"inset 0 0 10px 5px #2222258f");
            doc.setProperty('--box-shadow',"0 0 10px 5px #2222258f");
        }else{
            document.querySelector('body').classList.remove('dark-mode');
            document.querySelector('body').classList.add('light-mode');
            doc.setProperty('--activity-shadow',"inset 0 0 10px 5px #c5c5c58f"); 
            doc.setProperty('--box-shadow',"0 0 14px 1px #22222530"); 
            doc.setProperty('--color-darken-blue',"#F5F6F7");
            doc.setProperty('--color-dark-blue',"#F5F8F9");
            doc.setProperty('--color-superdark-blue',"#FFFFFF");
            doc.setProperty('--color-purple',"#304CFD");
            doc.setProperty('--color-crem',"#44566C");
            doc.setProperty('--color-white',"#000000");
            doc.setProperty('--color-crem-dark',"#292929");
            doc.setProperty('--color-dark-purple',"#1080f1");
        }
    }, [dark])

    const changeTheme =()=>{
        setDark(!dark);
    }
    const sun = "/assets/icons/sun.svg";
    const moon = "/assets/icons/moom.svg"

    return(
        <AppContextProvider >
        <div className="page-root">
        
            <div className="container">

                <div className="row justify-content-center">
                    <div className="col-12 col-lg-6 col-xl-3">
                   
                        <SideBar/>
                   
                    </div>
                    <div className="col-12 col-lg-6 col-xl-9" id="main_app">

                        <MainApp >
                            {children}     
                        </MainApp>

                    </div>

                </div>

            </div>
            <div className={dark ? "theme-container shadow-light" : "theme-container shadow-dark"} onClick={()=>{changeTheme()}}>
                <img id="theme-icon"    src={!dark ? moon : sun} alt="ERR"/>
            </div>
            
        </div>
        </AppContextProvider>
           
    )
}

export default Layout;