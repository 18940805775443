const host = process.env.REACT_APP_HOST;
const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Headers": "X-Custom-Header",
}
// get content 
export const fetchPagesContent = async (args)=>{
    const lang_id = args.queryKey[1];
    if (lang_id) {
        const resp = await  fetch(`${host}/api/content-pages/${lang_id}`,{
             headers: headers,
        });
        return resp.json(); 
    }
    const resp = await  fetch(`${host}/api/content-pages`);
    return resp.json(); 

}

// get languages 
export const fetchLanguages = async ()=>{
    const resp = await  fetch(`${host}/api/languages`, {
        headers: headers,
    });
    return resp.json();
}

// get menu items 
export const fetchMenuItems = async (args)=>{
    const lang_id = args.queryKey[1];
    if (lang_id) {
        const resp = await  fetch(`${host}/api/menu-items/${lang_id}`, {
         headers: headers,
    });
        return resp.json();
    }
    const resp = await  fetch(`${host}/api/menu-items`);
    return resp.json();
 
}
