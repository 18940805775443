import React,{useContext} from 'react'

import { Field, Form, Formik,ErrorMessage } from 'formik';
import { ContactValidationEn,ContactValidationFr } from '../validation/ContactValidation'
import { frPlaceHolders,enPlaceHolders,frLocos, enLocos } from '../Translate/Contact'
import { sendMailContact } from '../Api/Mutations'
import { useMutation } from 'react-query'
import ContactDialog  from '../components/ContactDialog'
import {AppContext} from '../context/AppContext'

export default function ContactForm() {

    const {messageSent,setMessageSent,language } = useContext(AppContext);
    const placeHolders = language?.key !="FR" ? frPlaceHolders : enPlaceHolders;
    const locos = language?.key !="FR" ? frLocos : enLocos;
    const  { 
        data,
        status,
        error,
        mutate
    } = useMutation(sendMailContact,{
        onSuccess : ()=>{
            setMessageSent(true);
        }
    })
    return (
        <Formik
            initialValues={{
                full_name: '',
                subject: '',
                email: '',
                phone: '',
                message: '',
            }}
            validationSchema={ language?.key !="FR" ? ContactValidationFr : ContactValidationEn}
            onSubmit={(values,{resetForm} )=> {
                mutate(values);
                resetForm({});
            }}
       >

        {
            ({ errors, touched ,values,isSubmitting})=>(
                
                <Form >
                        <h2 className="block-title mb-5">{ language?.key !="FR" ? "Formulaire de contact" : "Contact form"}</h2>
                        <div className="row g-4">
                            <div className="col-12 col-lg-6">
                                <div className="input-group">
                                    <span className="input-group-text" id="full_name"><i className="fa fa-user-o" aria-hidden="true"></i></span>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        placeholder={placeHolders.full_name}
                                        aria-label={placeHolders.full_name}
                                        name="full_name"
                                        aria-describedby="full_name"
                                    />
                                </div>
                                {
                                    touched.full_name && errors.full_name ? (
                                        <ErrorMessage className="err-validation" name="full_name" component="div" />
                                    ): undefined
                                }
                               
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="input-group">
                                    <span className="input-group-text" id="subject"><i className="fa fa-question-circle-o" aria-hidden="true"></i></span>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        placeholder={placeHolders.subject}
                                        aria-label={placeHolders.subject}
                                        name="subject"
                                        aria-describedby="subject"
                                    />
                                </div>
                                {
                                    touched.subject && errors.subject ? (
                                        <ErrorMessage className="err-validation" name="subject" component="div" />
                                    ): undefined
                                }
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="input-group">
                                    <span className="input-group-text" id="email"><i className="fa fa-envelope" aria-hidden="true"></i></span>
                                    <Field
                                        type="email"
                                        className="form-control"
                                        placeholder={placeHolders.email}
                                        aria-label={placeHolders.email}
                                        name="email"
                                        aria-describedby="input1"
                                    />
                                </div>
                                {
                                    touched.email && errors.email ? (
                                        <ErrorMessage className="err-validation" name="email" component="div" />
                                    ): undefined
                                }
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="input-group">
                                    <span className="input-group-text" id="phone"><i className="fa fa-phone" aria-hidden="true"></i></span>
                                    <Field
                                        type="text"
                                        className="form-control"
                                        placeholder={placeHolders.phone}
                                        aria-label={placeHolders.phone}
                                        aria-describedby="phone"
                                        name="phone"
                                    />
                                </div>
                                {
                                    touched.phone && errors.phone ? (
                                        <ErrorMessage className="err-validation" name="phone" component="div" />
                                    ): undefined
                                }
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <Field className="form-control" component="textarea" id="message" name="message" placeholder={placeHolders.message} rows="3"/>
                                </div>
                                {
                                    touched.message && errors.message ? (
                                        <ErrorMessage className="err-validation" name="message" component="div" />
                                    ): undefined
                                }
                            </div>
                            <div className="col-12">
                                <button type="submit" className="btn btn-purple" disabled={status=="loading"}>
                                {
                                    status=="loading" ? (
                                       <>
                                       <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        {locos.loading}
                                       </>
                                    ) : status =="success" ? (
                                        <>
                                            <i className="fa fa-check-square-o" aria-hidden="true"></i>
                                            {locos.success_alert}
                                        </>
                                    ): <><i className="fa fa-paper-plane" aria-hidden="true"></i> {locos.form_contact_btn_text}</>
                                }
                                  
                                </button>
                            </div>
                        </div>
                        {
                            messageSent ? (
                                <ContactDialog locos={locos}/>
                            ): undefined
                        }
                    </Form> 
            )
        }
           
        </Formik>
        
    )
}
