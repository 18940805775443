import React,{useContext} from 'react';
import {  motion } from "framer-motion";
import loadable from '@loadable/component'
import MetaTagsComponent from '../layout/MetaTags'
import {fetchPagesContent } from '../Api/Queries'
import { useQuery } from 'react-query'
import {AppContext} from '../context/AppContext'

// components
import AboutComponent from '../components/AboutComponent';
import WorkComponent   from '../components/WorkComponent';
import TechnologiesComponent from  '../components/TechnologiesComponent';
import DatabasesComponent from '../components/DatabasesComponent';
import CmsesComponent   from '../components/CmsesComponent';

export default function Front({pageVariants,pageTransition,pageStyle}) {
    const {lang_id } = useContext(AppContext);

   
    const { 
        data:content,
        status,
        error
    } = useQuery(['content',lang_id],fetchPagesContent,{
        refetchOnMount:false,
        refetchInterval:false,
        refetchOnWindowFocus:false,
    });
    
    const homeContent = status != "loading" ? content.find(item => item.slug == "/").content : null;
    const extras = status != "loading" ? content.find(item => item.slug == "/").extras : {};
    const my_domains = homeContent ? JSON.parse(homeContent.my_domains) : null;
    const backend_technologies = homeContent ? JSON.parse(homeContent.backend_technologies) : null;
    const front_technologies = homeContent ? JSON.parse(homeContent.front_technologies) : null;
    const cms_technologies = homeContent ? JSON.parse(homeContent.cms_technologies) : null;
    const databases = homeContent ? JSON.parse(homeContent.databases) : null;

    return (
        <React.Fragment>

            <MetaTagsComponent extras={extras}/>
         
            <motion.div
                style={pageStyle}
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
            >

                {/* about  */}
                <AboutComponent about_me={homeContent?.about_me} status={status} />
                {/* about  */}
                {/* work  */}
                <WorkComponent title={homeContent?.domains_title} domains={my_domains} status={status}/>
                {/* work  */}
                {/* backend_technologies */}
                <TechnologiesComponent title={homeContent?.backend_technologies_title} technologies={backend_technologies} status={status}/>
                {/* backend_technologies */}
                {/* Front_technologies */}
                <TechnologiesComponent title={homeContent?.front_technologies_title} technologies={front_technologies} status={status}/>
                {/* Front_technologies */}
                {/* Front_technologies */}
                <CmsesComponent title={homeContent?.cmses_title} technologies={cms_technologies} status={status}/>
                {/* Front_technologies */}
                {/* DatabasesComponent */}
                <DatabasesComponent title={homeContent?.databases_title} databases={databases} status={status}/>
            </motion.div>
        </React.Fragment>
    )
}
