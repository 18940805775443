import React,{useContext} from 'react'
import {AppContext} from '../context/AppContext'
import parse from 'html-react-parser';
export default function ContactDialog({locos}) {
    const {setMessageSent } = useContext(AppContext);
    return (
        <div className="ui-dialog">
            
            <div className="ui-dialog-icon">
                <img src="./assets/icons/sent.png" alt="" />
            </div>

            <h1 className="ui-dialog-title">{locos.dialog_title}</h1>
            <h2 className="ui-dialog-message">{parse(locos.dialog_sub_title)} </h2>
            <p>{locos.dialog_desc}</p>
            <a href="" className="ui-dialog-btn btn" onClick={
                (e)=>{
                    e.preventDefault();
                    setMessageSent(false);
                }
            }>Ok</a>
        </div>
    )
}
