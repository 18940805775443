import React from 'react'
import Skeleton from '@material-ui/lab/Skeleton';

export default function ResumeSkeletonComponent() {
    return (
        <React.Fragment>
        
                <h2 className="block-title"> <Skeleton variant="text" animation="wave" width={"30%"}   height={20}  /></h2>
                <ul className="timeline">
                    <li>
                        <h2> <Skeleton variant="text" animation="wave" width={"30%"}   height={20}  /></h2>
                        <Skeleton variant="text" animation="wave" width={"100%"}   height={20}  />
                        <Skeleton variant="text" animation="wave" width={"60%"}   height={20}  />
                    </li>
                    <li>
                        <h2> <Skeleton variant="text" animation="wave" width={"30%"}   height={20}  /></h2>
                        <Skeleton variant="text" animation="wave" width={"100%"}   height={20}  />
                        <Skeleton variant="text" animation="wave" width={"60%"}   height={20}  />
                    </li>
                    <li>
                        <h2> <Skeleton variant="text" animation="wave" width={"30%"}   height={20}  /></h2>
                        <Skeleton variant="text" animation="wave" width={"100%"}   height={20}  />
                        <Skeleton variant="text" animation="wave" width={"60%"}   height={20}  />
                    </li>
                </ul>
            </React.Fragment>
    )
}
